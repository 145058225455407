export default {
    "error.general_title": "Error",
    "error.general_description": "Internal error, please try again later.",
    "error.general_failed_loading_data": "Failed loading data",
    "error.general_failed_upload_file": "Upload of file '{file}' failed: {msg}",
    "error.general_cancel": "Cancel",
    "error.general_delete": "Delete",
    "success.general_title": "Success",
    "root.close": "Close",
    "root.organization": "Agency Name",
    "root.required": "This field is required.",
    "root.onlynumbers": "Only numbers",
    "root.name": "Name",
    "root.firstname": "First Name",
    "root.lastname": "Last Name",
    "root.email": "Email",
    "root.email_valid": "The email address is not valid",
    "root.phone": "Phone",
    "root.invalid.phone": "Invalid phone number",
    "root.invalid.postal.code": "Invalid postal code",
    "root.choose_password": "Choose a password",
    "root.choose_password_size": "Please enter at least 8 characters.",
    "root.choose_password_max": "The max size is 255.",
    "root.password": "Password",
    "root.confirm_password": "Repeat password",
    "root.confirm_password_match": "Passwords must match.",
    "root.password_changed": "Password changed.",
    "root.cancel": "Cancel",
    "root.create_account": "Sign up",
    "root.something_weng_wrong": "Something went wrong...",
    "root.already_have_account": "Already have an account? ",
    "root.return_to_login": "Return to log in",
    "root.trial_period_ends": "Trial period ends on",
    "root.last_subscription_period_end": "Last subscription period ends on",
    "root.subscription_ended": "Your subscription ended on",
    "root.manage_your_subscriptions": "Billing",
    "root.profile": "Profile",
    "root.change_password": "Change password",
    "root.profile_image": "Profile image",
    "root.upload_new_image": "Upload new image",
    "root.choose_file": "Choose File",
    "root.remove_image": "Remove Image",
    "root.save": "Save",
    "root.profile_updated": "Profile updated",
    "root.image_removed": "Image removed",
    "root.only_images_allowed": "Only image files allowed",
    "root.api_not_supported": "Sorry, FileReader API not supported",
    "root.file_uploaded": "File uploaded",
    "root.saved": "Saved",
    "root.back": "Back",
    "root.generic_max_size": "Max size is {max}",
    "root.generic_min_size": "Min size is {min}",

    // main menu Sidebar
    "mainmenu.home": "Home",
    "mainmenu.configuration": "Configuration",
    "mainmenu.about": "About",
    "mainmenu.templates": "Templates",
    "mainmenu.billing": "Billing",
    "mainmenu.api": "API",
    "mainmenu.help": "Help",

    "Billing.nav.balance": "Balance",
    "Billing.title": "Billing",
    "Billing.checkout.btn": "Checkout",

    "ValidateEmailModal.title": "Verify your account",
    "ValidateEmailModal.msg": "Your account has not been verified, yet. An e-mail including instructions on how to verify it has been sent out.",
    "ValidateEmailModal.resend.btn": "Resend email",
    "ValidateEmailModal.EmailSent": "Email sent",

    "Home.recent_transactions": "Last 20 transactions",
    "Home.remaining_api_calls": "Remaining API Calls",
    "Home.api_usage": "API Usage",
    "Home.table.id": "ID",
    "Home.table.templateId": "Template ID",
    "Home.table.templateName": "Template Name",
    "Home.table.format": "Format",
    "Home.table.created": "Created",
    "Home.table.download": "Download",
    "Home.remaining": "Remaining",
    "Home.used": "Used",
    "Home.api_calls": "API Calls",

    "Templates.title": "Templates",
    "Templates.new.btn": "Create New Template",

    "Templates.table.uuid": "Template ID",
    "Templates.table.name": "Name",
    "Templates.table.updated": "Updated",
    "Templates.table.type": "Type",
    "Templates.table.format": "Format",

    "Templates.delete.title": "Are you sure?",
    "Templates.delete.msg": "After deleting, you can't get it back!",
    "Templates.delete.success.msg": "Template deleted",

    "Templates.clone.title": "Are you sure?",
    "Templates.clone.msg": "Duplicate template {name}?",
    "Templates.clone.success.msg": "Template duplicated",

    "Templates.open.btn": "Open",
    "Templates.delete.btn": "Delete",
    "Templates.clone.btn": "Duplicate",
    "Templates.nobalance.title": "Not enough balance",
    "Templates.nobalance.msg": "You don't have enough balance, please add more",
    "Templates.nobalance.btn": "Add Balance",

    "NewTemplateModal.title": "Create new template",
    "NewTemplateModal.word": "Upload a Word (doc/docx) file to use as a PDF template",
    "NewTemplateModal.pdf": "Create PDF template using HTML/CSS",
    "NewTemplateModal.image": "Create image template using a visual editor",

    "TemplateWordCreate.upload.title": "Create new template from Word file",
    "TemplateWordCreate.info.alert": "Accepted files (.doc, .docx)",
    "TemplateWordCreate.file": "File",
    "TemplateWordCreate.name": "Template name",
    "TemplateWordCreate.createbtn": "Create",
    "TemplateWordCreate.created": "Template created",

    "TemplateWordCreate.select.file" : "Select file",
    "TemplateWordCreate.upload.created" : "File uploaded",
    "TemplateWordCreate.upload.error.msg" : "Error uploading the complete file, please try again",

    "TemplateEdit.name.saved": "Template name saved",
    "TemplateEdit.saved": "Template Saved",

    "TemplateEditImage.generate.full.title": "Are you sure?",
    "TemplateEditImage.generate.full.msg": "Overwrite current sample JSON?",
    "TemplateEditImage.generate.full.btn": "Continue",

    "TemplateEditImage.generate.compact.title": "Are you sure?",
    "TemplateEditImage.generate.compact.msg": "Overwrite current sample JSON?",
    "TemplateEditImage.generate.compact.btn": "Continue",

    "ModalEditTemplateName.title": "Edit name",
    "ModalEditTemplateName.name.label": "Name",
    "ModalEditTemplateName.modify.btn": "Change",


    "months.jan": "January",
    "months.feb": "February",
    "months.mar": "March",
    "months.apr": "April",
    "months.may": "May",
    "months.jun": "June",
    "months.jul": "July",
    "months.aug": "August",
    "months.sep": "September",
    "months.oct": "October",
    "months.nov": "November",
    "months.dec": "December",
}

