<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="sidebar-brand" style="text-indent: unset;">
                <router-link :to="{name: 'Templates'}">
                    <img src="/assets/images/logo-sm.png" class="img-fluid">
                </router-link>
            </li>
            <li :class="{ 'active': $route.name === 'Home' }">
                <router-link to="/"><i class="fa fa-home"></i> {{$t("mainmenu.home")}}</router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'api' }">
                <router-link :to="{name: 'Api'}">
                    <i class="fa fa-cog"></i>
                    {{$t("mainmenu.api")}}
                </router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'templates' }">
                <router-link :to="{name: 'Templates'}">
                    <i class="fa fa-shopping-cart"></i>
                    {{$t("mainmenu.templates")}}
                </router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'zapier' }">
                <router-link :to="{name: 'Zapier'}">
                    <i style="width: 30px;">
                        <img src ="/assets/images/zapier-icon.svg" alt="Zapier" class="zapier-icon">
                    </i>
                    Zapier
                </router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'billing' }">
                <router-link :to="{name: 'Billing'}">
                    <i class="fa fa-dollar-sign"></i>
                    {{$t("mainmenu.billing")}}
                </router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'help' }">
                <router-link :to="{name: 'Help'}">
                    <i class="fa fa-question-circle"></i>
                    {{$t("mainmenu.help")}}
                </router-link>
            </li>

            <li class="d-md-none mr-2 ml-2 mt-5">
                <select v-model="selectedLang"
                        @change="changeLangAndSave(selectedLang)"
                        class="form-control"
                >
                    <option v-bind:key="index" v-for="(lang, index) in languageOptions" :value="lang.value"> {{lang.label}}</option>
                </select>
            </li>
            <!--<li :class="{ 'active': $route.name === 'About' }">
                <router-link to="/about"><i class="fa fa-user"></i> {{$t("mainmenu.about")}}</router-link>
            </li>-->
        </ul>
    </div>
</template>
<style scoped>
    li > a > i {
        width:30px;
        text-align: center;
    }

    .zapier-icon {
        height:20px;
        padding-left:10px;
        margin-bottom: 4px;
    }

    .active .zapier-icon {
        filter: invert(1);
    }
</style>
<script>

    // import {getLanguageOptions} from "../../language-utils";
    // import {i18n} from "../../main"
    import {mapActions, mapGetters} from "vuex";
    import {i18n} from "@/main";
    import {getLanguageOptions} from "@/languageUtils";

    export default {
        components: {
        },
        data () {
            return {
                selectedLang: i18n.locale,
                languageOptions: getLanguageOptions()

                // selectedMenu: '',

                // showAgencyMenu: false
            }
        },
        watch: {
            "profile.lang"() {
                this.selectedLang = this.profile.lang;
                i18n.locale = this.profile.lang;
            }
        },
        mounted () {
            // ambas estas vars devem estar no main.scala.html
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters({
                profile: 'profile',
                isAdmin: 'isAdmin'
            }),
        },
        methods: {
            ...mapActions(
                {
                    changeLangAndSave: 'changeLangAndSave',
                },
            ),
        }
    }
</script>
