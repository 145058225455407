<template>
    <div class="row align-middle">
        <div class="col-md-8 col-sm-10 mx-auto mt-2">
            <div class="card">
                <div class="card-header">
                    <h1>{{$t("root.profile")}}</h1>
                </div>

                <div class="card-body">

                    <div class="row justify-content-end mb-4">
                        <div class="col-auto">
                            <router-link :to="{name : 'ChangePassword'}" class="btn btn-primary zap-btn-rosa ">{{$t("root.change_password")}}</router-link>
                        </div>
                    </div>

                    <form v-on:submit.prevent="editUserProfile" novalidate>
                        <div class="form-group row">
                            <label for="inputFirst" class="col-3  col-form-label text-right">{{$t("root.name")}}:</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="inputFirst"
                                       v-model="user.name"
                                       :class="{ 'is-invalid':  $v.user.name.$error }"
                                       :placeholder='$t("root.name")'>
                                <div class="invalid-feedback" v-if="!$v.user.name.required">{{$t("root.required")}}</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail" class="col-3 col-form-label text-right">{{$t("root.email")}}:</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="inputEmail"
                                       v-model="user.email"
                                       :class="{ 'is-invalid':  $v.user.email.$error }"
                                       :placeholder='$t("root.email")'>
                                <div class="invalid-feedback" v-if="!$v.user.email.required">{{$t("root.required")}}</div>
                                <div class="invalid-feedback" v-if="!$v.user.email.email">{{$t("root.email_valid")}}</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPhone" class="col-3 col-form-label text-right">{{$t("root.phone")}}:</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="inputPhone"
                                       v-model="user.phone"
                                       :class="{ 'is-invalid':  $v.user.phone.$error }"
                                       :placeholder='$t("root.phone")'>
                            </div>
                        </div>

                        <div class="row justify-content-end">
                            <div class="col-auto">
                                <button type="reset" @click.stop.prevent="goBack" class="btn btn-secondary-o">{{$t("root.cancel")}}</button>
                                <v-button type="submit" :loading="loading" class="btn btn-primary zap-btn-rosa ">{{$t("root.save")}}</v-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import axios from 'axios'
    import {required, email} from 'vuelidate/lib/validators'
    import {showSuccess} from '../utils'

    import VButton from "../components/VButton";
    import {mapActions} from 'vuex';
    import {ajaxErrorMixin} from "../mixins";


    export default {
        name: "UserProfile",

        components: {
            // ImageCropModal,
            VButton
        },
        mixins: [
            ajaxErrorMixin
        ],

        data() {
            return {
                user: {
                    name: null,
                    lastName: null,
                    email: null,
                    phone: null,
                },
                loading: false,
                loadingRemove: false,
            }
        },

        watch: {
        },

        mounted() {
            this.loadUserData()
        },


        validations: {
            user: {
                name: {
                    required,
                },
                email: {
                    required,
                    email
                },
                phone: {
                },
            },
        },

        computed: {
        },
        methods: {
            ...mapActions(
                {
                    loadProfileData: 'loadProfileData',
                },
            ),
            refresh() {
                this.loadUserData()
            },
            goBack() {
                this.$router.go(-1);
            },
            loadUserData() {
                this.loadProfileData().then(response => {
                    this.user = response;
                }, error => {
                    this.handleAjaxError(error)
                })
            },

            editUserProfile() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    // console.log("INVALID")
                    return
                }
                const self = this;
                this.loading = true;
                // eslint-disable-next-line
                let url = jsRoutes.controllers.Account.editUserProfile().url
                axios.post(url, this.user).then(() => {
                    self.loading = false;
                    self.loadUserData();
                    showSuccess(
                        self.$t("success.general_title"),
                        self.$t("root.profile_updated")
                    );
                }).catch(error => {
                    self.loading = false;
                    self.handleAjaxError(error)
                })

            },
        },
    }
</script>

<style scoped>

</style>
