<template>
  <div id="wrapper" :class="{'toggled' : showSidebar}">
    <sidebar></sidebar>
    <div id="content">
        <nav class="navbar navbar-light bg-light border-bottom navbar-expand">
            <button type="button" @click="toggleSidebar" class="btn btn-default zap-text-white">
                <span class="sr-only">Toggle navigation</span>
                <span class="fa fa-bars fa-lg"></span>
            </button>

            <div class="collapse navbar-collapse zap-text-white">
                <div class="mr-auto"></div>
                <ul class="navbar-nav mt-2">
<!--                    <li class="nav-item">
                        <router-link style="color:blue;" class="nav-link" :to="{name : 'Billing'}">
                            {{ $t("Billing.nav.balance")}}: €{{profile.balance}}
                        </router-link>
                    </li>-->
                    <li class="nav-item" ref="noticeable">
                        <div id="noticeable-widget"></div>
                    </li>
                    <li class="nav-item d-none d-md-block">
                        <div>
                            <select v-model="selectedLang"
                                    @change="changeLangAndSave(selectedLang)"
                                    class="form-control"
                            >
                                <option v-bind:key="index" v-for="(lang, index) in languageOptions" :value="lang.value"> {{lang.label}}</option>
                            </select>
                        </div>
                    </li>
<!--                    <li class="nav-item">
                        <notifications-widget></notifications-widget>
                    </li>-->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="d-none d-md-block">{{profile.name}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right " aria-labelledby="navbarDropdown">
                            <p class="dropdown-item-text">{{profile.name}}</p>
                            <div class="dropdown-divider d-md-none"></div>
                            <router-link class="dropdown-item" :to="{name : 'UserProfile'}">{{$t("root.profile")}}</router-link>
                            <router-link class="dropdown-item" to="/change-password">{{$t("root.change_password")}}</router-link>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="/logout"><i class="fa fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <main role="main" class="container-fluid main-section">
            <router-view :key="$route.fullPath"></router-view>
        </main>
    </div>
      <login-modal :show="showLoginModal"></login-modal>
  </div>
</template>

<style lang="css" scoped>
@media (min-width: 768px) {
    .d-md-block {
        /* display: block!important; */
        display: inline !important;
    }
}

.main-section {
    padding-left: 5px;
    padding-right: 5px;
}

</style>
<script>
    import Sidebar from "./components/Sidebar";
    import {mapActions, mapGetters} from "vuex";
    import LoginModal from "./components/LoginModal";
    import {i18n} from "@/main";
    import $ from "jquery";
    import {getLanguageOptions} from "@/languageUtils";
    // import NotificationsWidget from "./components/NotificationsWidget";

    export default {
        components: {
            Sidebar,
            // NotificationsWidget,
            LoginModal
        },
        data () {
            return {
                showSidebar: true,

                selectedLang: i18n.locale,
                languageOptions: getLanguageOptions()
            }
        },
        created() {
            if (localStorage.getItem("showSidebar") !== null) {
                this.showSidebar = localStorage.getItem("showSidebar") === "true"
            } else if ($(window).width() < 768) {
                this.toggleSidebar();
            }
        },
        mounted() {
            this.loadProfileData();
            this.$gtag.set({"user_id": window.userId})
            // eslint-disable-next-line
            noticeable.render('widget', 'o9pzPPmj5BKyAbWoRlkl', {root: this.$refs.noticeable});
        },
        computed: {
            ...mapGetters({
                showLoginModal: 'showLoginModal',
                profile: 'profile',
                // notifications: 'notifications'
            }),
        },
        watch: {
            // profile() {
            //     this.selectedLang = this.profile.lang;
            //     i18n.locale = this.profile.lang;
            // },
            "profile.lang"() {
                this.selectedLang = this.profile.lang;
                i18n.locale = this.profile.lang;
            }
        },
        methods: {
            ...mapActions(
                {
                    loadProfileData: 'loadProfileData',
                    setShowLoginModal: 'setShowLoginModal',
                    setShowLoginModalExpired: 'setShowLoginModalExpired',
                    changeLangAndSave: 'changeLangAndSave',
                },
            ),
            toggleSidebar() {
                this.showSidebar = !this.showSidebar;
                localStorage.setItem("showSidebar", this.showSidebar);
            }
        }
    }
</script>
