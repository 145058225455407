import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/change-password",
        name: "ChangePassword",
        component: () =>
            import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue")
    },
    {
        path: "/user-profile",
        name: "UserProfile",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
    },
    {
        path: "/",
        name: "Home",
        meta: { section: 'home'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/Home.vue")
    },
    {
        path: "/billing",
        name: "Billing",
        meta: { section: 'billing'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "billing" */ "../views/Billing.vue")
    },
    {
        path: "/templates",
        name: "Templates",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/Templates.vue")
    },
    /*{
        path: "/template-create/pdf",
        name: "TemplateCreatePdf",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/!* webpackChunkName: "templates" *!/ "../views/TemplateCreatePdf.vue")
    },*/
    {
        path: "/template-create/word",
        name: "TemplateCreateWord",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/TemplateCreateWord.vue")
    },
    {
        path: "/template-create/html",
        name: "TemplateCreateHtml",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/TemplateCreateHtml.vue")
    },
    {
        path: "/template-create/image",
        name: "TemplateCreateImage",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/TemplateCreateImage.vue")
    },
    {
        path: "/template-create/wysiwyg",
        name: "TemplateCreateWys",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/TemplateCreateWys.vue")
    },
    {
        path: "/template/:id",
        name: "TemplateEdit",
        meta: { section: 'templates'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "templates" */ "../views/TemplateEdit.vue")
    },
    {
        path: "/api-key",
        name: "Api",
		meta: { section: 'api'},
		// route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "api" */ "../views/ApiKey.vue")
    },
    {
        path: "/help",
        name: "Help",
		meta: { section: 'help'},
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Help.vue")
    },
    {
        path: "/zapier",
        name: "Zapier",
		meta: { section: 'zapier'},
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Zapier.vue")
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
