<template>
    <div class="row">
        <div class="col-12">
            <form v-on:submit.prevent="createAccount" id="signupForm" novalidate>
                <div class="form-group" :class="{ 'has-error': $v.signupData.name.$error }">
                    <label for="name" class="control-label">{{ $t("root.name") }} *</label>
                    <input class="form-control" type="text" name="name" id="name" placeholder=""
                           v-model.trim="$v.signupData.name.$model"
                           :class="{ 'is-invalid':  $v.signupData.name.$error }"
                    />
                    <span class="invalid-feedback" v-show="$v.signupData.name.$error && !$v.signupData.name.required">{{ $t("root.required") }}</span>
                    <span class="invalid-feedback" v-show="$v.signupData.name.$error && !$v.signupData.name.maxLength">{{ $t("root.generic_max_size", {max: 255}) }}</span>
                </div>

                <div class="form-group" :class="{ 'has-error': $v.signupData.email.$error }">
                    <label for="email" class="control-label">{{ $t("root.email") }} *</label>
                    <input class="form-control" type="email" name="email" id="email"
                           v-model="$v.signupData.email.$model"
                           :class="{ 'is-invalid':  $v.signupData.email.$error }"
                    />
                    <span class="invalid-feedback" v-show="$v.signupData.email.$error && !$v.signupData.email.required">{{ $t("root.required") }}</span>
                    <span class="invalid-feedback" v-show="$v.signupData.email.$error && !$v.signupData.email.email">{{ $t("root.email_valid") }}</span>
                </div>

                <div class="form-group" :class="{ 'has-error': $v.signupData.password.$error }">
                    <label for="password" class="control-label">{{ $t("root.password") }} *</label>
                    <input class="form-control" type="password" id="password"
                           v-model="$v.signupData.password.$model"
                           :class="{ 'is-invalid':  $v.signupData.password.$error }"
                    />
                    <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.required">{{ $t("root.required") }}</span>
                    <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.minLength">{{ $t("root.generic_min_size", {min: 8}) }}</span>
                    <span class="invalid-feedback" v-show="$v.signupData.password.$error && !$v.signupData.password.maxLength">{{ $t("root.generic_max_size", {max: 255}) }}</span>
                </div>
                <div class="form-group" :class="{ 'has-error': $v.signupData.repeatPassword.$error }">
                    <label for="repeatPassword" class="control-label">{{ $t("root.confirm_password")}} *</label>
                    <input class="form-control" type="password" id="repeatPassword"
                           v-model="$v.signupData.repeatPassword.$model"
                           :class="{ 'is-invalid':  $v.signupData.repeatPassword.$error }"
                    />
                    <span class="invalid-feedback" v-show="$v.signupData.repeatPassword.$error && !$v.signupData.repeatPassword.required">{{ $t("root.required") }}</span>
                    <span class="invalid-feedback" v-show="$v.signupData.repeatPassword.$error && !$v.signupData.repeatPassword.sameAsPassword">{{ $t("root.confirm_password_match") }}</span>
                </div>

                <div v-if="showError" class="alert alert-danger" v-html="errorMsg"></div>

                <v-button type="submit" class="btn btn-primary btn-block" :loading="createLoading">{{ $t("root.create_account") }}</v-button>
                <hr/>
                <a href="/authenticate/google" class="mb-3 btn btn-block btn-outline-secondary mt-2 p-0">
                    <img alt="google icon" title="google" style="height:35px;" src="/assets/icons/google.svg">
                    Signup with Google
                </a>
                <p class="text-muted text-center mt-2">{{ $t("root.already_have_account") }}</p>
                <a class="btn-block text-center" :href="loginUrl">{{ $t("root.return_to_login") }}</a>
                <br/><br/>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {required, sameAs, email, minLength, maxLength} from "vuelidate/lib/validators";
    import VButton from "../components/VButton";

    export default {
        components: {
            VButton,
        },
        data() {
            return {
                signupData: {
                    name: '',
                    email: '',
                    password: '',
                    repeatPassword: '',
                },
                showError: false,
                errorMsg: "",

                createLoading: false
            }
        },
        validations: {
            signupData: {
                name: {
                    required: required,
                    maxLength: maxLength(255)
                },
                email: {
                    required: required,
                    email: email
                },
                password: {
                    required: required,
                    minLength: minLength(6),
                    maxLength: maxLength(255),
                },
                repeatPassword: {
                    required: required,
                    sameAsPassword: sameAs('password')
                },
            }
        },
        computed: {
            loginUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.Application.index().url;
            },
            googleLoginUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.Application.signupGoogle().url;
            }
        },
        mounted() {
            // this.$gtag.event('hello')
        },
        methods: {
            createAccount() {
                this.showError = false;
                this.errorMsg = "";
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doCreateAccount();
            },

            doCreateAccount: function () {
                const self = this;
                this.createLoading = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.doSignup().url;
                axios.post(url, this.signupData)
                    .then(function (/*response*/) {
                        self.createLoading = false;
                        // eslint-disable-next-line
                        window.location.href = jsRoutes.controllers.Application.index().url;
                        /*self.$gtag.event('signup', {
                            email: self.signupData.email ,
                            "event_callback": function () {
                                // console.log("HIT CALLBACK")
                                // eslint-disable-next-line
                                window.location.href = jsRoutes.controllers.Application.index().url;
                            }
                        })*/
                        //$("#createBtn").button("reset");
                        // eslint-disable-next-line
                        // window.location.replace(jsRoutes.controllers.Application.index().url);
                        // window.location.href = jsRoutes.controllers.Application.login().url;
                    })
                    .catch(function (error) {
                        self.createLoading = false;
                        self.showError = true;
                        self.errorMsg = error.response;
                        if (error.response.status === 500) {
                            self.errorMsg = "Something went wrong...";
                        } else {
                            self.errorMsg = error.response.data;
                        }
                        //showError("ERROR", response);
                    });
            }
        },
    }
</script>

<style scoped>

</style>
