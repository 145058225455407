import Vue from "vue";

Vue.config.ignoredElements = ["zapier-full-experience", "zapier-zap-templates"];

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

/*
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://dccfa5176f064fefa74de2e5eb36547f@o227127.ingest.sentry.io/5547944",
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracingOptions: {
            trackComponents: true,
        },

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}
*/

import "./registerServiceWorker";
import router from "./router/router";
import store from "./store/store";
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

import './assets/simple-sidebar.css'
import './assets/main.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'

import toastr from 'toastr';
import 'toastr/toastr.scss';

toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

Vue.use(VueCookies)
Vue.use(VueI18n);

let userSelectedLang = Vue.$cookies.get('PLAY_LANG');

import en from './i18n/en'
import pt from './i18n/pt'
const messages = {
    en: en,
    pt: pt
};

export function calculateLang() {
    if(userSelectedLang)
        return userSelectedLang;
    /*if (navigator.language.startsWith('ru')) {
        return 'ru';
    }*/
    if (navigator.language.startsWith('pt')) {
        return 'pt';
    }
    return 'en';
}
export const i18n = new VueI18n({
    locale: calculateLang(), // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
})



import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.config.productionTip = false;

// import VueNativeSock from 'vue-native-websocket';

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: { id: "G-C8VSKKSCYX" },
    enabled: process.env.NODE_ENV === 'production',
    // disableScriptLoad: true
}, router);


import App from "./App.vue";
if(document.getElementById("app")) {
/*
    let protocol;
    if (window.location.protocol === "http:"){
        protocol = "ws";
    } else {
        protocol = "wss";
    }
    const port = window.location.port ? ':' + window.location.port: '';

    let websocketUrl = protocol+"://"+window.location.hostname + port +"/ws"
    Vue.use(VueNativeSock, websocketUrl, {
        store: store,
        connectManually: false,
        format: 'json',
        reconnection: true, // (Boolean) whether to reconnect automatically (false)
        // reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
        reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
    });
*/

    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount("#app");
}

import Signup from './views/Signup.vue'
// console.log("#### SIGNUP CONTAINER? " + document.getElementById("signupContainer"));
if(document.getElementById("signupContainer")) {
    new Vue({
        i18n,
        render: h => h(Signup)
    }).$mount('#signupContainer')
}

import UserProfile from './views/UserProfile'
if(document.getElementById("userProfileContainer")) {
    new Vue({
        i18n,
        render: h => h(UserProfile)
    }).$mount('#userProfileContainer')
}
