export default {
    "error.general_title": "Erro",
    "error.general_description": "Erro interno, por favor volte a tentar mais tarde.",
    "error.general_failed_loading_data": "Erro ao obter dados",
    "error.general_failed_upload_file": "Upload de ficheiro '{file}' falhou: {msg}",
    "error.general_cancel": "Cancelar",
    "error.general_delete": "Apagar",
    "success.general_title": "Sucesso",
    "root.close": "Fechar",
    "root.organization": "Agency Name",
    "root.required": "Campo obrigatório",
    "root.onlynumbers": "Apenas números.",
    "root.name": "Nome",
    "root.firstname": "Primeiro Nome",
    "root.lastname": "Último Nome",
    "root.email": "Email",
    "root.email_valid": "O Email não é válido",
    "root.phone": "Telefone",
    "root.invalid.phone": "Número de telefone não é válido",
    "root.invalid.postal.code": "Código postal não é válido",
    "root.choose_password": "Escolha uma password",
    "root.choose_password_size": "A password tem de ter pelo menos 8 caracteres",
    "root.choose_password_max": "O tamanho máximo é 255",
    "root.password": "Password",
    "root.confirm_password": "Repetir password",
    "root.confirm_password_match": "As passwords têm de ser iguais.",
    "root.password_changed": "Password alterada.",
    "root.cancel": "Cancelar",
    "root.create_account": "Registar",
    "root.something_weng_wrong": "Algo correu mal...",
    "root.already_have_account": "Já tem conta? ",
    "root.return_to_login": "Fazer log in",
    "root.trial_period_ends": "Trial period ends on",
    "root.last_subscription_period_end": "Last subscription period ends on",
    "root.subscription_ended": "Your subscription ended on",
    "root.manage_your_subscriptions": "Facturação",
    "root.profile": "Perfil",
    "root.change_password": "Alterar password",
    "root.profile_image": "Image de Perfil",
    "root.upload_new_image": "Carregar nova imagem",
    "root.choose_file": "Escolher Ficheiro",
    "root.remove_image": "Remover Imagem",
    "root.save": "Guardar",
    "root.profile_updated": "Perfil actualizado",
    "root.image_removed": "Image removida",
    "root.only_images_allowed": "Apenas permitidos ficheiros de imagem",
    "root.api_not_supported": "Lamentamos, FileReader API não é suportada",
    "root.file_uploaded": "Ficheiro carregado",
    "root.saved": "Guardado",
    "root.back": "Voltar",
    "root.generic_max_size": "O tamanho máximo é {max}",
    "root.generic_min_size": "O tamanho mínimo é {min}",

    // main menu Sidebar
    "mainmenu.home": "Home",
    "mainmenu.configuration": "Configuração",
    "mainmenu.about": "Sobre",
    "mainmenu.templates": "Transcrições",
    "mainmenu.billing": "Facturação",

    "Billing.nav.balance": "Saldo",
    "Billing.title": "Adicionar saldo",
    "Billing.select.amount": "Selecione o valor a adicionar",
    "Billing.checkout.btn": "Fazer checkout",

    "ValidateEmailModal.title": "Verifique o seu email",
    "ValidateEmailModal.msg": "A sua conta não foi verificada ainda. Uma mensagem de email com instruções de verificação foi enviada.",
    "ValidateEmailModal.resend.btn": "Reenviar email",
    "ValidateEmailModal.EmailSent": "Email enviado",

    "Templatestatus.CREATED": "Criada",
    "Templatestatus.DOWNLOADING_YT": "A fazer download...",
    "Templatestatus.DOWNLOAD_FAILED": "Download falhou",
    "Templatestatus.PROCESSING": "A processar",
    "Templatestatus.TRANSCRIBING": "A Docsfolder",
    "Templatestatus.DONE": "Completa",
    "Templatestatus.ERROR": "Falhou",

    "Home.recent_transactions": "Ultimas  20 transacções",
    "Home.remaining_api_calls": "Chamadas à API disponíveis",
    "Home.api_usage": "Utilização API",
    "Home.table.id": "ID",
    "Home.table.templateId": "ID Transcrição",
    "Home.table.templateName": "Nome da transcrição",
    "Home.table.format": "Formato",
    "Home.table.created": "Criado",
    "Home.table.download": "Download",
    "Home.remaining": "Disponíveis",
    "Home.used": "Utilizadas",
    "Home.api_calls": "Chamadas API",

    "Templates.title": "Transcrições",
    "Templates.new.btn": "Criar Nova transcrição",

    "Templates.table.name": "Nome",
    "Templates.table.length": "Duração",
    "Templates.table.price": "Preço",
    "Templates.table.lang": "Língua",
    "Templates.table.created": "Data",
    "Templates.table.status": "Estado",

    "Templates.delete.title": "Tem a certeza?",
    "Templates.delete.msg": "Após apagar, não pode recuperar a transcrição!",
    "Templates.delete.success.msg": "Transcrição apagada",

    "Templates.transcribe.btn": "Docsfolder ({cur}{price})",
    "Templates.transcribe.processing": "Transcrição em execução. Estimamos que demore cerca de {mins} minuto(s).<br/>Irá receber um email a notificar quando terminar!",
    "Templates.open.btn": "Abrir",
    "Templates.delete.btn": "Apagar",
    "Templates.nobalance.title": "Saldo Insuficiente",
    "Templates.nobalance.msg": "Não tem saldo suficiente, por favor carregue mais",
    "Templates.nobalance.btn": "Adicionar Saldo",

    "NewTemplateModal.title": "Escolha a fonte do ficheiro",
    "NewTemplateModal.youtube": "Usar um URL do YouTube",
    "NewTemplateModal.upload": "Carregar ficheiro de áudio ou vídeo do computador",

    "TemplateCreate.youtube.title": "Criar nova transcrição",
    "TemplateCreate.upload.title": "Criar nova transcrição",
    "TemplateCreate.info.alert": "<b>Tamanho máximo de ficheiro: 2Gb</b>. Aceites ficheiros de vídeo/audio (.wav, .mp3, .m4a, .flac, .wmv, .ogg, .avi, .mkv)",
    "TemplateCreate.url": "Youtube URL",
    "TemplateCreate.lang": "Língua",
    "TemplateCreate.speakers": "Interlocutores",
    "TemplateCreate.createbtn": "Criar",
    "TemplateCreate.lang.none.placeholder": "Escolher língua",

    "TemplateCreate.yt.processing": "A processar vídeo...",
    "TemplateCreate.yt.created": "Download do vídeo em progresso. Assim que terminar ficará disponível para Docsfolder!",

    "TemplateCreateUpload.select.file" : "Seleccionar ficheiro",

    "TemplateCreateUpload.upload.created" : "Ficheiro carregado",
    "TemplateCreateUpload.upload.error.msg" : "Erro ao carregar ficheiro na totalidade, por favor volte a tentar",

    "TemplateEdit.skipforward.btn": "Avançar +10s",
    "TemplateEdit.skipbackward.btn": "Recuar -10s",
    "TemplateEdit.playbackspeed.btn": "Velocidade de reprodução",
    "TemplateEdit.playpause.btn": "Play/Pausa",
    "TemplateEdit.undo.btn": "Anular",
    "TemplateEdit.redo.btn": "Refazer",
    "TemplateEdit.save.btn": "Guardar",
    "TemplateEdit.export.btn": "Exportar",
    "TemplateEdit.saved": "Transcrição Guardada",
    "TemplateEdit.speaker.edit.label": "Clique para editar interlocutor",

    "TemplateEdit.restore.are_you_sure_title": "Tem a certeza?",
    "TemplateEdit.restore.are_you_sure_msg": "Tem certeza de que deseja restaurar a transcrição original? Vai perder todas as alterações feitas",
    "TemplateEdit.restore.btn": "Restaurar",
    "TemplateEdit.restore.success": "Transcrição restaurada",

    "ModalEditSpeaker.title": "Editar interlocutor",
    "ModalEditSpeaker.speakerName.label": "Alterar nome do interlocutor",
    "ModalEditSpeaker.applyAllCheckbox.label": "Aplicar alteração a todas as entradas com ",
    "ModalEditSpeaker.modify.btn": "Alterar",

    "ModalEditTemplateName.title": "Editar nome",
    "ModalEditTemplateName.name.label": "Nome",
    "ModalEditTemplateName.modify.btn": "Alterar",

    "ModalExportTemplate.title": "Exportar",
    "ModalExportTemplate.format.label": "Formato",
    "ModalExportTemplate.export.btn": "Exportar",

    "ExportTemplate.format.txt.label": "Texto (.txt)",
    "ExportTemplate.format.pdf.label": "PDF (.pdf)",
    "ExportTemplate.format.word.label": "Word (.docx)",
    "ExportTemplate.format.srt.label": "Legendas SRT (.srt)",
    "ExportTemplate.format.sbv.label": "Legendas Youtube (.sbv)",
    "ExportTemplate.format.vtt.label": "Legendas WebVTT (.vtt)",

    "lang.af-ZA": "Africâner",
    "lang.ar-AE": "Árabe, Golfo",
    "lang.ar-SA": "Árabe, Padrão moderno",
    "lang.zh-CN": "Chinês, simplificado",
    "lang.zh-TW": "Chinês, tradicional",
    "lang.da-DK": "Dinamarquês",
    "lang.nl-NL": "Holandês",
    "lang.en-AU": "Inglês, Australiano",
    "lang.en-GB": "Inglês, Britânico",
    "lang.en-IN": "Inglês, Indiano",
    "lang.en-IE": "Inglês, Irlandês",
    "lang.en-NZ": "Inglês, Nova Zelândia",
    "lang.en-AB": "Inglês, Escocês",
    "lang.en-ZA": "Inglês, África do Sul",
    "lang.en-US": "Inglês, Estados Unidos",
    "lang.en-WL": "Inglês, Galês",
    "lang.fr-FR": "Francês",
    "lang.fr-CA": "Francês, Canadiano",
    "lang.fa-IR": "Farsi",
    "lang.de-DE": "Alemão",
    "lang.de-CH": "Alemão, suíço",
    "lang.he-IL": "Hebraico",
    "lang.hi-IN": "Hindi, indiano",
    "lang.id-ID": "Indonésio",
    "lang.it-IT": "Italiano",
    "lang.ja-JP": "Japonês",
    "lang.ko-KR": "Coreano",
    "lang.ms-MY": "Malaio",
    "lang.pt-PT": "Português",
    "lang.pt-BR": "Português, Brasileiro",
    "lang.ru-RU": "Russo",
    "lang.es-ES": "Espanhol",
    "lang.es-US": "Spanish, Estados Unidos",
    "lang.ta-IN": "Tâmil",
    "lang.te-IN": "Telugu",
    "lang.th-TH": "Tailandês",
    "lang.tr-TR": "Turco",


    "months.jan": "Janeiro",
    "months.feb": "Fevereiro",
    "months.mar": "Março",
    "months.apr": "Abril",
    "months.may": "Maio",
    "months.jun": "Junho",
    "months.jul": "Julho",
    "months.aug": "Agosto",
    "months.sep": "Setembro",
    "months.oct": "Outubro",
    "months.nov": "Novembro",
    "months.dec": "Dezembro",
}
