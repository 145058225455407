
export function getLanguageOptions() {
	let result = [
		{
			value: "en",
			label: "English",
		},
		// {
		//     value: "pt",
		//     label: "Português",
		// }
	];
	return result;
}

