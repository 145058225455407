import $ from 'jquery';
import bootbox from 'bootbox';
import toastr from 'toastr';
import {i18n} from "./main";

export function copyData(dataObj) {
    return JSON.parse(JSON.stringify(dataObj));
}

export function generateUUID() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}


export function guid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}


export function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}


export function stringNotEmpty(str) {
    return str && str.trim().length > 0;
}


import moment from 'moment'
// moment.locale('pt')

export function getDateStringMinusMonth(numMonths) {
    return moment().subtract(numMonths, "months").format("YYYY-MM-DD");
}

export function getDateStringPlusMonth(numMonths) {
    return moment().add(numMonths, "months").format("YYYY-MM-DD");
}

export function getDateStringPlusDay(numDays) {
    return moment().add(numDays, "days").format("YYYY-MM-DD");
}


/**
 * devolve pais de address components de place detail (google places api)
 * @param addressComponents
 * @returns {*}
 */
export function getCountryNameFromGoogleAdr(addressComponents) {
    //find country name
    for (let i=0; i < addressComponents.length; i++) {
        for (let j=0; j < addressComponents[i].types.length; j++) {
            if (addressComponents[i].types[j] === "country") {
                let country = addressComponents[i];
                console.log(country.long_name)
                console.log(country.short_name)
                return country.long_name;
            }
        }
    }
    return null;
}


export function getLocalityNameFromGoogleAdr(addressComponents) {
    //find locality name
    for (let i=0; i < addressComponents.length; i++) {
        for (let j=0; j < addressComponents[i].types.length; j++) {
            if (addressComponents[i].types[j] === "locality") {
                let locality = addressComponents[i];
                console.log(locality.long_name)
                console.log(locality.short_name)
                return locality.long_name;
            }
        }
    }
    return null;
}


export function getAdminAreaNameFromGoogleAdr(addressComponents) {
    //find admin area name (Lisboa, Leiria, etc)
    for (let i=0; i < addressComponents.length; i++) {
        for (let j=0; j < addressComponents[i].types.length; j++) {
            if (addressComponents[i].types[j] === "administrative_area_level_1") {
                let adminArea = addressComponents[i];
                console.log(adminArea.long_name)
                console.log(adminArea.short_name)
                return adminArea.long_name;
            }
        }
    }
    return null;
}

export function dynamicSort(arr, property, sortOrder) {
    if (property === undefined || property === null || property === "")
        return arr;
    let comparator = function (a, b) {
        let val1 = a[property];
        let val2 = b[property];

        let result = (val1 < val2) ? -1 : (val1 > val2) ? 1 : 0;

        return result * sortOrder;
    };
    return arr.slice().sort(comparator);
}


/**
 * procura em array ultimo index cuja property de objecto seja igual a value.
 *
 * @param array array a pesquisar
 * @param property property onde verificar valor
 * @param value valor a comparar
 * @returns number index de objecto no array, ou 0 caso nao encontre
 */
export function lastIndexOf(array, property, value) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i][property] === value) {
            return i;
        }
    }
    return 0;
}

export function findInArrayByUid(array, id) {
    let result = $.grep(array, function (e) {
        return e._uid === id;
    });
    if (result.length === 0) {
        return null;
    } else if (result.length === 1) {
        return result[0];
    } else {
        // multiple items found
        return result[0];
    }
}


export function removeFromArray(array, property, value) {
    let index = -1;
    for(let i = 0; i < array.length; i += 1) {
        if(array[i][property] === value) {
            index = i;
            break;
        }
    }
    if (index !== -1) {
        array.splice(index, 1);
    }
    /*array.forEach(function (result, index) {
        if (result[property] === value) {
            //Remove from array
            array.splice(index, 1);
        }
    });*/
}



/**
 * Strict object type check. Only returns true
 * for plain JavaScript objects.
 *
 * @param {*} obj
 * @return {Boolean}
 */

let toString = Object.prototype.toString;
let OBJECT_STRING = '[object Object]';

export function isPlainObject(obj) {
    return toString.call(obj) === OBJECT_STRING;
}

/**
 * String contain helper
 *
 * @param {*} val
 * @param {String} search
 */

export function contains(val, search) {
    var i;
    if (isPlainObject(val)) {
        var keys = Object.keys(val);
        i = keys.length;
        while (i--) {
            if (contains(val[keys[i]], search)) {
                return true;
            }
        }
    } else if (Array.isArray(val)) {
        i = val.length;
        while (i--) {
            if (contains(val[i], search)) {
                return true;
            }
        }
    } else if (val != null) {
        return val.toString().toLowerCase().indexOf(search) > -1;
    }
}


/**
 * Convert an Array-like object to a real Array.
 *
 * @param {Array-like} list
 * @param {Number} [start] - start index
 * @return {Array}
 */

export function toArray(list, start) {
    start = start || 0;
    var i = list.length - start;
    var ret = new Array(i);
    while (i--) {
        ret[i] = list[i + start];
    }
    return ret;
}


/**
 * Create a range array from given number.
 *
 * @param {Number} n
 * @return {Array}
 */

export function range(n) {
    var i = -1;
    var ret = new Array(Math.floor(n));
    while (++i < n) {
        ret[i] = i;
    }
    return ret;
}


export function convertArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else if (isPlainObject(value)) {
        // convert plain object to array.
        var keys = Object.keys(value);
        var i = keys.length;
        var res = new Array(i);
        var key;
        while (i--) {
            key = keys[i];
            res[i] = {
                $key: key,
                $value: value[key]
            };
        }
        return res;
    } else {
        if (typeof value === 'number' && !isNaN(value)) {
            value = range(value);
        }
        return value || [];
    }
}


export function showSuccess(title, message) {
    if (message !== undefined && message !== null) {
        toastr.success(message, title);
    } else {
        toastr.success(title);
    }
}


export function showWarning(title, message) {
    if (message !== undefined && message !== null) {
        toastr.warning(message, title);
    } else {
        toastr.warning(title);
    }
}

export function isAuthError(error) {
    if (error.status) {
        return error.status === 401 || error.status === 403
    } else if (error.response && error.response.status) {
        return error.response.status === 401 || error.response.status === 403
    }
    return false;
}

/**
 * handle de erros de axios, mas devolve mensagem de erro
 *
 * @param error objecto devolvido pelo metodo catch do axios
 */

export function getAjaxErrorMsg(error) {
    if (error.response !== undefined) {
        if (error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    } else if (error.responseText !== undefined) {
        if (error.responseText.trim().length > 0) {
            return error.responseText;
        } else {
            return error.statusText;
        }
    } else {
        return error;
    }
}

export function showError(title, message) {
    if (typeof message !== 'undefined' && message.indexOf("<!DOCTYPE html>") > -1) {
        // faz o handle da pagina de erro devolvida pelo Global.onError ou Global.onBadRequest
        if ($(message).find("#error").length > 0) {
            message = $(message).find("#error").html();
        }
    }

    if (message !== undefined && message !== null) {
        toastr.error(message, title);
    } else {
        toastr.error(title);
    }
}

export function showErrorDialog(title, message) {
    bootbox.dialog({
        message: '<div class="alert alert-danger" role="alert">' + message + '</div>',
        title: title,
        buttons: {
            ok: {
                label: i18n.tc('root.close'),
                className: "btn-primary btn-outline",
            }
        }
    });
}


export function showSuccessDialog(title, message, callback) {
    bootbox.dialog({
        message: '<div class="alert alert-success" role="alert">' + message + '</div>',
        title: title,
        buttons: {
            ok: {
                label: i18n.tc('root.close'),
                className: "btn-primary  btn-outline",
                callback: callback,
            }
        }
    });
}


/**
 *
 * @param title
 * @param message
 * @param problemList Array:
 * [
 *  {
 *      date: "",
 *      list: ["", ""]
 *  }
 * ]
 */
export function showItineraryDatesError(title, message, problemList) {
    message += "<br/><br/><ul>";
    problemList.forEach(problem => {
        message += "    <li>" + problem.date;
        message += "        <ul>";
        problem.list.forEach(name => {
            message += "        <li>" + name + "</li>";
        });
        message += "        </ul>";
        message += "    </li>";
    });
    message += "</ul>";

    bootbox.dialog({
        message: '<div class="alert alert-danger" role="alert">' + message + '</div>',
        title: title,
        buttons: {
            ok: {
                label: i18n.tc('root.close'),
                className: "btn-primary  btn-outline",
            }
        }
    });
}

export function showWarningDialog(title, message) {
    bootbox.dialog({
        message: '<div class="alert alert-warning" role="alert">' + message + '</div>',
        title: title,
        buttons: {
            ok: {
                label: i18n.tc('root.close'),
                className: "btn-primary  btn-outline",
            }
        }
    });
}

export function showCustomDialog(title, message, okLabel, okClass, okCallback, cancelCallback) {
    if (!cancelCallback) {
        cancelCallback = function() {};
    }
    bootbox.dialog({
        message: message,
        title: title,
        onEscape: true,
        buttons: {
            cancel: {
                label: i18n.tc('root.cancel'),
                className: "btn-link",
                callback: cancelCallback
            },
            ok: {
                label: okLabel,
                className: okClass,
                callback: okCallback
            }
        }
    });
}

export function showHelpDialog(title, message) {
    bootbox.dialog({
        message: message,
        title: title,
        buttons: {
            ok: {
                label: "Fechar",
                className: "btn-primary",
            }
        }
    });
}
